<template>
  <div :class="`page channel-menu ${product ? 'bg-grey' : ''}`">
    <!-- <div class="menu-list" v-if="product === ''">
      <h3>채널별 메뉴 확인하기</h3>
      <a-button class="btn" type="primary" size="large" block @click="product = 'Autoimmune'"
        >프로그랍-Autoimmune</a-button
      >
      <a-button class="btn" type="primary" size="large" block @click="product = 'transplantation'"
        >프로그랍-transplantation</a-button
      >
      <a-button class="btn" type="primary" size="large" block @click="product = 'harome'">하루날베타미가 뉴스</a-button>
    </div> -->
    <div class="menu-list" v-if="product === 'Autoimmune'">
      <h3>프로그랍-Autoimmune</h3>
      <a-button class="btn" size="large" block @click="clickLink(1)">1. 제품정보</a-button>
      <a-button class="btn" size="large" block @click="clickLink(2)">2. 최신 뉴스 및 임상정보</a-button>
      <a-button class="btn" size="large" block @click="clickLink(3)">3. 임상 data Summary</a-button>
      <a-button class="btn" size="large" block @click="clickLink(4)">4. 상담 및 문의</a-button>
      <a-button class="btn" size="large" block @click="clickLink(5)">5. 한국아스텔라스제약 홈페이지</a-button>
      <a-button class="btn" size="large" block @click="clickLink(6)">6. 개인정보처리 방침</a-button>
      <a-button class="btn" size="large" block @click="clickLink(7)">7. 0월 0일 웨비나 사전등록</a-button>
    </div>
    <div class="menu-list" v-if="product === 'transplantation'">
      <h3>프로그랍-transplantation</h3>
      <a-button class="btn" size="large" block @click="clickLink(1)">1. 제품정보</a-button>
      <a-button class="btn" size="large" block @click="clickLink(2)">2. 최신 뉴스 및 임상정보</a-button>
      <a-button class="btn" size="large" block @click="clickLink(3)">3. 임상 data Summary</a-button>
      <a-button class="btn" size="large" block @click="clickLink(4)">4. 상담 및 문의</a-button>
      <a-button class="btn" size="large" block @click="clickLink(5)">5. 한국아스텔라스제약 홈페이지</a-button>
      <a-button class="btn" size="large" block @click="clickLink(6)">6. 개인정보처리 방침</a-button>
      <a-button class="btn" size="large" block @click="clickLink(7)">7. 0월 0일 웨비나 사전등록</a-button>
    </div>
    <div class="menu-list" v-if="product === 'harome'">
      <h3>하루날베타미가 뉴스 제품</h3>
      <a-button class="btn" size="large" block @click="clickLink(1)">1. 제품정보</a-button>
      <a-button class="btn" size="large" block @click="clickLink(2)">2. 최신 뉴스 및 임상정보</a-button>
      <a-button class="btn" size="large" block @click="clickLink(3)">3. 임상 data Summary</a-button>
      <a-button class="btn" size="large" block @click="clickLink(4)">4. 상담 및 문의</a-button>
      <a-button class="btn" size="large" block @click="clickLink(5)">5. 한국아스텔라스제약 홈페이지</a-button>
      <a-button class="btn" size="large" block @click="clickLink(6)">6. 개인정보처리 방침</a-button>
      <a-button class="btn" size="large" block @click="clickLink(7)">7. 0월 0일 웨비나 사전등록</a-button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
export default {
  data() {
    return {
      product: '', // Autoimmune / transplantation / harome
    };
  },
  mounted() {
    if (this.kakaoInfo === null) {
      this.saveKakaoInfo(this.$route.query);
    }

    this.getHcpInfo(this.kakaoInfo).then((res) => {
      console.log('', res);
      switch (res.kakaoChannel) {
        case '테스트':
          this.product = 'harome';
          break;
        default:
          this.product = 'harome';
          break;
      }
      this.isHcp = res.isHcp;
    });
  },
  methods: {
    clickLink(e) {
      let url = '';
      let tabName = '';
      switch (e) {
        case 1:
          tabName = 'product_info';
          url =
            'https://nedrug.mfds.go.kr/pbp/CCBBB01/getItemDetailCache?cacheSeq=200511277aupdateTs2023-07-12%2017:56:55.201201b';
          break;
        case 2:
          tabName = 'news_clinical_info';
          url = 'https://www.astellas.com/kr/ko/news?tab=latest&page=0';
          break;
        case 3:
          tabName = 'card_news';
          url = '';
          alert('준비중입니다.');
          return;
          break;
        case 4:
          tabName = 'inquiry';
          url = 'https://www.astellas.com/kr/ko/medicines-information-submission';
          break;
        case 5:
          tabName = 'homepage';
          url = 'https://www.astellas.com/kr/';
          break;
        case 6:
          tabName = 'policy';
          url = 'https://www.astellas.com/kr/ko/privacy-policy';
          break;

        case 7:
          tabName = 'webinar';
          url = '';
          alert('준비중입니다.');
          return;
          break;
      }
      window.open(url, `'(${tabName})'`);
    },
    ...mapActions(['getHcpInfo', 'saveKakaoInfo']),
  },
  computed: {
    ...mapState(['kakaoInfo']),
  },
};
</script>
<style lang="scss">
.channel-menu {
  height: 100vh;
  &.bg-grey {
    background-color: #e8e8e8;
  }
  h3 {
    text-align: center;
    margin: 4rem 0;
  }
  .menu-list {
    .btn {
      margin-bottom: 10px;
    }
  }
}
</style>